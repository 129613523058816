<template>
  <v-card
    flat
    tile
    color="grey"
    class="pa-16"
    height="100vh"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-flex
        xs4
        text-center
      >
        <router-link to="/">
          <img 
            src="@/assets/images/logo/logomark.svg"
          >
        </router-link>
        <v-card
          flat
          tile
          color="white"
          class="mt-16 pa-1"
        >
          <v-card-text class="text--primary text-h5 text-none text-left font-weight-600 pb-0">
            {{ $t("thanks") }} {{ name }}!
          </v-card-text>
          <v-card-text class="text--primary font-size-18 lh-22 text-none text-left font-weight-600">
            {{ $t("email_verify") }} <br> {{ $t("account") }}.
          </v-card-text>
          <v-card-text class="text--primary  text-wrap font-size-15 font-size-15__lh-18 text-none text-left font-weight-600 pb-0 pt-1">
            {{ $t("email_sent") }}: {{ email }}
          </v-card-text>
          <v-card-text class="text--primary font-size-14 font-size-14__lh-14 text-none text-left font-weight-medium pt-2">
            {{ $t("not_get_email") }} <router-link
              to="#"
              class="text-decoration-none primary--text"
            >
              {{ $t("send_again") }}
            </router-link>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "EmailConfirmation",
  data() {
    return {
      name: 'Eva',
      email: 'evagardner@gmail.com',
    }
  },
};
</script>